<template>
  <div>
    <el-breadcrumb>
      <el-breadcrumb-item>系统设置</el-breadcrumb-item>
      <el-breadcrumb-item>修改登陆密码</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="box">
      <div class="jbxx">
        <div class="jbtop">
          <div class="icon"></div>
          <div class="tit">用户信息</div>
        </div>
        <el-form ref="form" :model="form" label-width="200px">
          <el-row>
            <el-col :span="10" style="display: flex;">
              <el-form-item label="用户账号：">
                <el-input v-model="form.loginName" placeholder="请输入用户账号" style="width: 280px;"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="10" style="display: flex;">
              <el-form-item label="登陆密码：">
                <el-input v-model="form.password" placeholder="请输入登陆密码" style="width: 280px;"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="10">
              <el-button type="text" style="margin-left: 80px;">修改密码</el-button>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="10" style="display: flex;">
              <el-form-item label="新密码：">
                <el-input v-model="form.newPassword" placeholder="请输入新密码" style="width: 280px;"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
        <div class="butt">
          <!-- <el-button style="width: 140px;height: 50px;" @click="$router.push('/auditBusinessone')">取消</el-button> -->
          <el-button style="width: 140px;height: 50px;" type="primary" @click="onSubmit">保存</el-button>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import { quillEditor } from 'vue-quill-editor'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
export default {
  components: {   //界面组件引用
    quillEditor
  },
  data() {
    return {
      form: {
      },
    };
  },
  created() {
    this.form = JSON.parse(localStorage.getItem('userInfo')).userInfo
    console.log("_+_+_+", this.form);
  },
  mounted() {

  },
  methods: {
    onSubmit() {
      let _this = this
      var obj = {}
      obj.id = this.form.id
      obj.oldPassword = this.form.password
      obj.password = this.form.newPassword
      // console.log("KJKJFBV", obj);

      this.$http.post('/userInfo/updatePwd', obj).then(function (response) {
        console.log(response);
        if (response.data.code == 200) {
          _this.$message({
            message: '重置密码成功',
            type: 'success'
          });
          _this.$router.push('/login')
        }

      })
    },
  }
};
</script>

<style lang="less" scoped>
.box {
  background-color: #fff;
  padding-top: 20px;

  .jbxx {
    padding-right: 10px;

    .jbtop {
      height: 56px;
      background-color: #eef1f6;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      padding-left: 20px;
      margin-left: 10px;
      margin-bottom: 40px;

      .icon {
        width: 6px;
        height: 19px;
        border-radius: 10px;
        background-color: #f77f1f;
        margin-right: 10px;
      }

      .tit {
        font-weight: 700;
      }


    }

    .edi {
      margin-left: 20px;
      height: 450px;
      display: flex;

      .editor {
        width: 817px;
        height: 372px;
        margin-top: 20px;
      }
    }
  }

  .butt {
    display: flex;
    margin-left: 50%;
    margin-top: 15%;
  }

  .tab {
    padding: 20px;
  }

  .dia {
    .diafour {
      display: flex;
      justify-content: space-between;
      padding: 20px 230px;
    }
  }
}
</style>